import { AddIcon } from "@chakra-ui/icons";
import { Center, Container, HStack } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import CampaignCard from "../components/campaigns/CampaignCard";
import NewCampaign from "../components/campaigns/NewCampaign";
import Pagination from "../components/global/Pagination";
import Title from "../components/global/Title";
import LoadingPage from "../pages/LoadingPage";
import { primaryColor } from "../utils/constants";

function CampaignsList({ allowCreation, loading, title, campaigns }) {
  return (
    <Container my="20">
      <HStack alignItems={"center"} justifyContent={"space-between"} w="100%">
        <Title>{title}</Title>
        {allowCreation && (
          <Link to={"/campaigns/create"}>
            <Center mb={10} borderRadius={"100%"} bgColor={primaryColor} as="div" w={"30px"} h={"30px"}>
              <AddIcon color="white" />
            </Center>
          </Link>
        )}
      </HStack>
      {loading && <LoadingPage />}
      {allowCreation && <NewCampaign />}
      {campaigns &&
        campaigns.map((campaign) => {
          return <CampaignCard key={Math.random()} campaign={campaign} />;
        })}
      <Pagination />
    </Container>
  );
}

export default CampaignsList;
