import { Box, Container, Divider, Flex, Icon, Image, Text } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { useGlobalState } from "../store/GlobalStore";
import { primaryColor } from "../utils/constants";

function Header() {
  const { user, color_theme } = useGlobalState();
  return (
    <Box mb="8">
      <Container>
        <Flex alignItems={"center"} justifyContent={"space-between"}>
          <Link to="/">
            <Flex alignItems={"center"}>
              <Image py="5" width="150px" src={color_theme === "light" ? "/logo.png" : "/logo_white.png"} alt="Logo de Publish" />
              <Text fontSize={"sm"} as="span">
                Entreprise
              </Text>
            </Flex>
          </Link>
          <Flex alignItems={"center"}>
            <Text mr="4" fontWeight={"500"} color={"white"} py="2" px="4" borderRadius={"1000px"} fontSize={"1rem"} bgColor={primaryColor}>{user?.balance || 0} F.</Text>
            <Link to="/account">
              <Icon fill={primaryColor} height="30px" viewBox="0 0 24 24" width="30px" xmlns="http://www.w3.org/2000/svg">
                <path d="m14.67 13.389h-5.34a5.836 5.836 0 0 0 -5.83 5.83v1.781a.5.5 0 0 0 .5.5h16a.5.5 0 0 0 .5-.5v-1.781a5.836 5.836 0 0 0 -5.83-5.83z" />
                <circle cx="12" cy="7" r="4.5" />
              </Icon>
            </Link>
          </Flex>
        </Flex>
      </Container>
      <Divider />
    </Box>
  );
}

export default Header;
