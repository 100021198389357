import React, { useEffect, useState } from "react";
import Layout from "../components/global/Layout";
import Breadcrumb from "../partials/Breadcrumb";
import CampaignsList from "../partials/CampaignsList";
import Statistics from "../partials/Statistics";
import { getCampaigns, useCampaignDispatch, useCampaignState } from "../store/CampaignStore";

function Home() {
  const [stats, setStats] = useState([]);
  const { loading, campaigns } = useCampaignState();
  const dispatchCampaigns = useCampaignDispatch();

  useEffect(() => {
    getCampaigns(dispatchCampaigns);
  }, [dispatchCampaigns]);

  const breadcrumbItems = [
    {
      name: "Accueil",
      path: "/",
    },
  ];

  useEffect(() => {
    if (campaigns) {
      setStats([
        {
          label: "Clics",
          value: {
            today: campaigns.map((c) => c.stats.clics.today).reduce((prev, curr) => prev + curr,0),
            week: campaigns.map((c) => c.stats.clics.week).reduce((prev, curr) => prev + curr,0),
            all: campaigns.map((c) => c.stats.clics.all).reduce((prev, curr) => prev + curr,0),
          },
        },
        {
          label: "Campagnes",
          value: {
            today: campaigns.length,
            week: campaigns.length,
            all: campaigns.length,
          },
        },
      ]);
    }
  }, [campaigns]);
  return (
    <Layout>
      <Breadcrumb items={breadcrumbItems} />
      <Statistics stats={stats} />
      {campaigns && <CampaignsList allowCreation title="Campagnes" loading={loading} campaigns={campaigns} />}
    </Layout>
  );
}

export default Home;
