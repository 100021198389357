/* eslint-disable no-useless-escape */
import { primaryColor } from "./constants";

export function transactionStatusColor(status) {
  switch (status) {
    case "pending":
      return "#616161";
    case "done":
      return primaryColor;
    case "failed":
      return "#FF1111";
    default:
      return "#616161";
  }
}

export function mapperRoute(route) {
  return route;
  // if (process.env.NODE_ENV !== "production") {
  //   return route + ".json";
  // }
  // return route;
}

export function mapperUserFields(fields) {
  let userInfos = {
    ...fields,
    meta: {
      age: fields.age,
      target_country: fields.target_country,
      residence_country: fields.residence_country,
    },
  };
  return userInfos;
}

export function currencyConverter(amount, fromCurrency, toCurrency) {
  if (fromCurrency === "XAF") {
    if (toCurrency === "EUR") {
      return amount / 655;
    } else if (toCurrency === "USD") {
      return amount / 640;
    } else {
      return amount;
    }
  }
  return amount;
}

export function validLink(link) {
  console.log("to check validation : " + link);
  if (link.slice(0, 4) === "tel:") {
    return isGoodPhone(link.slice(4));
  } else if (link.slice(0, 7) === "mailto:") {
    const expression = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    const regex = new RegExp(expression);
    return link.slice(7).match(regex);
  } else if (link.includes("wa.me")) {
    return isGoodPhone("+"+link.slice(14));
  } else {
    return isAGoodUrl(link);
  }
}
export function isGoodPhone(phone) {
  const expression = /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}/gi;
  const regex = new RegExp(expression);
  return phone.match(regex);
}

export function isAGoodUrl(url) {
  const expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
  const regex = new RegExp(expression);
  return url.match(regex);
}
