import { Box, Flex, Input } from "@chakra-ui/react";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Badge from "../global/Badge";
import PhoneInput from "../global/PhoneInput";

function LinkToShareInput({ defaultValue, onChange, ...props }) {
  const [linkType, setLinkType] = useState("url");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [url, setUrl] = useState(defaultValue);
  useEffect(() => {
    if (linkType === "url") {
      onChange(url);
    } else if (linkType === "email") {
      onChange("mailto:" + email);
    } else if (linkType === "phone") {
      onChange("tel:" + phone);
    } else {
      onChange("https://wa.me/" + phone?.replace("+", ""));
    }
  }, [linkType]);
  return (
    <Box {...props}>
      <Flex flexWrap={"wrap"}>
        <Badge active={linkType === "url"} onClick={() => setLinkType("url")}>
          Site web ou Réseaux
        </Badge>
        <Badge active={linkType === "whatsapp"} onClick={() => setLinkType("whatsapp")}>
          N° Whatsapp
        </Badge>
        <Badge active={linkType === "phone"} onClick={() => setLinkType("phone")}>
          N° Portable
        </Badge>
        <Badge active={linkType === "email"} onClick={() => setLinkType("email")}>
          Email
        </Badge>
      </Flex>
      {linkType === "url" && (
        <Input
          type="url"
          px={6}
          py={7}
          placeholder="Commence avec https://"
          value={url}
          onChange={(e) => {
            setUrl(e.target.value);
            onChange(e.target.value);
          }}
        />
      )}
      {linkType === "email" && (
        <Input
          type="email"
          px={6}
          py={7}
          placeholder="Votre adresse email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            onChange("mailto:" + e.target.value);
          }}
        />
      )}
      {["phone", "whatsapp"].includes(linkType) && (
        <PhoneInput
          placeholder="Votre numéro de téléphone"
          value={phone}
          onChange={(v) => {
            setPhone(v);
            if (linkType === "whatsapp") {
              onChange("https://wa.me/" + v?.replace("+", ""));
            } else {
              onChange("tel:" + v);
            }
          }}
        />
      )}
    </Box>
  );
}

export default LinkToShareInput;
