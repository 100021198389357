import {
  Box,
  Button,
  Center,
  Container,
  FormControl,
  Image,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Navigate } from "react-router";
import { Link } from "react-router-dom";
import LayoutAuth from "../partials/LayoutAuth";
import PhoneInput from "../components/global/PhoneInput";
import Footer from "../partials/Footer";
import {
  changeUserPassword,
  getUser,
  useGlobalDispatch,
  useGlobalState,
} from "../store/GlobalStore";
import { primaryColor } from "../utils/constants";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../utils/firebase";

function ForgottenPassword() {
  const { color_theme, loading, error, user } = useGlobalState();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [showEditPassword, setShowEditPassword] = useState(false);

  useEffect(() => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible", // or 'normal' for visible widget
          callback: (response) => {
            console.log("reCAPTCHA solved");
          },
          "expired-callback": () => {
            console.log("reCAPTCHA expired");
          },
        }
      );
    }
    window.recaptchaVerifier.render().then((widgetId) => {
      window.recaptchaWidgetId = widgetId;
    });
  }, []);

  const handleSendCode = () => {
    const appVerifier = window.recaptchaVerifier;

    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        console.log("confirmationResult", confirmationResult);
        setConfirmationResult(confirmationResult);
        toast({
          title: "Code envoyé",
          description: "Un code de vérification a été envoyé à votre téléphone",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch({
          type: "error",
          payload: error.message,
        });
      });
  };

  const handleVerifyCode = () => {
    confirmationResult
      .confirm(verificationCode)
      .then((result) => {
        // User is signed in
        setShowEditPassword(true);
        setConfirmationResult(null);
        toast({
          title: "Changez votre mot de passe",
          description: "Désormais, vous pouvez changer votre mot de passe",
          duration: 9000,
          isClosable: true,
        });
        console.log("User signed in successfully:", result.user);
      })
      .catch((error) => {
        dispatch({
          type: "error",
          payload: error.message,
        });
      });
  };
  const dispatch = useGlobalDispatch();
  const [fields, setFields] = useState({
    tel: "",
    password: "",
    confirm_password: "",
    name: "",
  });
  const toast = useToast();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      getUser(dispatch);
    }
  }, []);

  const changeField = (value, field) => {
    setFields({ ...fields, [field]: value });
  };
  const changePassword = () => {
    if (fields.confirm_password === fields.password) {
      changeUserPassword(dispatch, fields);
    } else {
      toast({
        title: "Error",
        description: "Les mots de passe ne sont pas les mêmes",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  if (user && !error) {
    return <Navigate to="/" />;
  }

  return (
    <LayoutAuth>
      <Container>
        <Box w="100%" py={18}>
          <Center mt={5}>
            <Image
              src={color_theme === "light" ? "/logo.png" : "/logo_white.png"}
              height={"3rem"}
              alt="Logo de Publish"
            />
          </Center>
          <Box textAlign={"center"} mt={8} px={5}>
            <span>
              Gagnez de l'argent en partageant des contenus sur vos réseaux :{" "}
              <br />
              <strong>Whatsapp, Dikalo, Twitter, Facebook, etc</strong>
            </span>
          </Box>
        </Box>
        <Box textAlign={"center"} px={5}>
          <Text fontSize="4xl" fontWeight={"800"} my={10}>
            Mot de passe oublié
          </Text>

          <div id="recaptcha-container"></div>
          <FormControl my="4">
            <PhoneInput
              placeholder="Votre numéro de téléphone"
              value={fields.tel}
              onChange={(v) => {
                changeField(v, "tel");
                setPhoneNumber(v);
              }}
            />
          </FormControl>
          {confirmationResult ? (
            <div>
              <FormControl my="4">
                <Input
                  type="text"
                  px={6}
                  py={7}
                  placeholder="Code de vérification"
                  onChange={(e) => setVerificationCode(e.target.value)}
                />
              </FormControl>
              <Button
                bgColor={primaryColor}
                onClick={handleVerifyCode}
                fontWeight={500}
                width="100%"
                color="white"
                py={7}
                isLoading={loading}
              >
                Vérifier le code
              </Button>
            </div>
          ) : showEditPassword ? (
            <>
              <FormControl my="4">
                <Input
                  type="password"
                  px={6}
                  py={7}
                  placeholder="Mot de passe"
                  onChange={(e) => changeField(e.target.value, "password")}
                />
              </FormControl>
              <FormControl my="4">
                <Input
                  type="password"
                  px={6}
                  py={7}
                  placeholder="Confirmation de mot de passe"
                  onChange={(e) =>
                    changeField(e.target.value, "confirm_password")
                  }
                />
              </FormControl>
              <Button
                bgColor={primaryColor}
                onClick={changePassword}
                fontWeight={500}
                width="100%"
                color="white"
                py={7}
                isLoading={loading}
              >
                Changer mon mot de passe{" "}
              </Button>
            </>
          ) : (
            <Button
              bgColor={primaryColor}
              onClick={handleSendCode}
              fontWeight={500}
              width="100%"
              color="white"
              py={7}
              isLoading={loading}
            >
              Envoyer le code
            </Button>
          )}
        </Box>
        <Box my={8} mt={4} px={5} textAlign={"right"} fontSize={20}>
          <Text as="small">
            Vous avez déjà un compte ?{" "}
            <Link to={"/login"}>
              <Text color={primaryColor} as="span">
                connectez-vous
              </Text>
            </Link>
            .
          </Text>
        </Box>
      </Container>
      <Footer />
    </LayoutAuth>
  );
}

export default ForgottenPassword;
