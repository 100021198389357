import { Text } from "@chakra-ui/react";
import React from "react";

function Title({ children, ...props }) {
  return (
    <Text as="h2" className="title" fontSize={"1.5rem"} mb="10" fontWeight="700" {...props}>
      {children}
    </Text>
  );
}

export default Title;
