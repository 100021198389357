import {
  Button,
  Center,
  FormControl,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import PhoneInput from "../global/PhoneInput";
import { Form, Formik } from "formik";
import { useState } from "react";
import { currencyConverter } from "../../utils/helpers";
import { STRIPE_FEES } from "../../utils/constants";
import { useCampaignState } from "../../store/CampaignStore";

function ActivateButton({ user, campaign, onSubmit, onCancel }) {
  const { payment_campaign_link } = useCampaignState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const paymentModes = [
    { value: "MTN", label: "MTN Mobile Money" },
    { value: "ORANGE", label: "Orange Money" },
    { value: "CARD", label: "Carte bancaire" },
  ];
  const currencies = [
    { value: "XAF", label: "F CFA (XAF)" },
    { value: "XOF", label: "F CFA (XOF)" },
    { value: "EUR", label: "€ (EU)" },
    { value: "USD", label: "$ (US)" },
  ];
  const defaultAmount = campaign.ppc * 100;
  const [fields, setFields] = useState({
    phone_number: user.phone_number,
    email: user.email,
    service: "CARD",
    currency: "XAF",
    amount: defaultAmount,
  });
  const updateField = (value, field) => {
    let _fields = { ...fields };
    let amountWithFees = defaultAmount;
    if (field === "currency") {
      if (value === "XAF" || value === "XOF") {
        _fields = { ..._fields, amount: amountWithFees.toFixed(0) };
      } else {
        _fields = {
          ..._fields,
          amount: currencyConverter(
            amountWithFees + amountWithFees * STRIPE_FEES,
            "XAF",
            value
          ).toFixed(2),
        };
      }
    }
    if (field === "service") {
      if (value === "CARD") {
        _fields = {
          ..._fields,
          currency: "EUR",
          amount: currencyConverter(amountWithFees, "XAF", "EUR").toFixed(2),
        };
        // if (_fields.currency === "XAF" || _fields.currency === "XOF") {
        //   _fields = { ..._fields, amount: amountWithFees.toFixed(0) };
        // } else {
        // }
      } else {
        _fields = { ..._fields, amount: defaultAmount, currency: "XAF" };
      }
    }
    setFields({ ..._fields, [field]: value });
  };
  const close = () => {
    onClose();
    onCancel();
  };

  return (
    <>
      <Button my={4} colorScheme="green" onClick={onOpen}>
        Activer ma campagne
      </Button>
      <Modal isOpen={isOpen} onClose={close} isCentered>
        <ModalOverlay />

        {payment_campaign_link ? (
          <ModalContent>
            <ModalHeader>Activer la campagne {campaign.name}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              Cliquez sur le bouton ci-dessous pour aller à la page de paiement
              <Center my={5}>
                <a href={payment_campaign_link}>
                  <Button colorScheme={"whatsapp"}>
                    Finaliser le paiement
                  </Button>
                </a>
              </Center>
            </ModalBody>
          </ModalContent>
        ) : (
          <Formik
            initialValues={fields}
            onSubmit={(values, actions) => {
              if (fields.service === "CARD" && fields.email === "") {
              } else {
                onSubmit(fields);
              }
            }}
          >
            {(props) => {
              return (
                <Form>
                  <ModalContent>
                    <ModalHeader>
                      Activer la campagne {campaign.name}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      {fields.service === "CARD" ? (
                        <>
                          <FormControl
                            my={8}
                            isInvalid={
                              fields.service === "CARD" && fields.email === ""
                            }
                          >
                            <Text fontSize={15}>Votre adresse email</Text>
                            <Input
                              size="lg"
                              type="email"
                              placeholder="Votre adresse email"
                              defaultValue={fields.email}
                              onChange={(e) =>
                                updateField(e.target.value, "email")
                              }
                            />
                          </FormControl>
                          <FormControl my={8}>
                            <Text fontSize={15}>Monnaie</Text>
                            <Select
                              size="lg"
                              defaultValue={fields.currency}
                              onChange={(e) =>
                                updateField(e.target.value, "currency")
                              }
                            >
                              {currencies.map((currency) => {
                                return (
                                  <option
                                    key={"option" + currency.value}
                                    value={currency.value}
                                  >
                                    {currency.label}
                                  </option>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </>
                      ) : (
                        <FormControl my={8}>
                          <Text fontSize={15}>Votre numéro de téléphone</Text>
                          <PhoneInput
                            placeholder="Entrez un numéro de téléphone"
                            value={fields.phone_number}
                            onChange={(value) =>
                              updateField(value, "phone_number")
                            }
                          />
                        </FormControl>
                      )}
                      <FormControl my={8}>
                        <Text fontSize={15}>Mode de paiement</Text>
                        <Select
                          size="lg"
                          defaultValue={fields.service}
                          onChange={(e) =>
                            updateField(e.target.value, "service")
                          }
                        >
                          {paymentModes.map((mode) => {
                            return (
                              <option
                                key={"option" + mode.value}
                                value={mode.value}
                              >
                                {mode.label}
                              </option>
                            );
                          })}
                        </Select>
                        <Text as="small">
                          Des frais de transaction peuvent s'appliquer lors du
                          paiement.
                        </Text>
                      </FormControl>
                    </ModalBody>
                    <ModalFooter>
                      <Button variant="ghost" onClick={close}>
                        Annuler
                      </Button>
                      <Button type="submit" colorScheme={"green"} mr={3}>
                        Payer {fields.amount} {fields.currency}
                      </Button>
                    </ModalFooter>
                  </ModalContent>
                </Form>
              );
            }}
          </Formik>
        )}
      </Modal>
    </>
  );
}

export default ActivateButton;
