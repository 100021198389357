// firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyALCZbERs39jGY6i1hRDSJIGpXSIkLuzeQ",
  authDomain: "com-backend.firebaseapp.com",
  projectId: "com-backend",
  storageBucket: "com-backend.appspot.com",
  messagingSenderId: "525458932300",
  appId: "1:525458932300:web:1f0bfa80ab362f375c2ae9",
  measurementId: "G-QPPS4CC3QG",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

export { auth };
