import {
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import LinkToShareInput from "../components/campaigns/LinkToShareInput";
import MediaUploader from "../components/campaigns/MediaUploader";
import { darkGreen, MIN_PPC, primaryColor } from "../utils/constants";
import { validLink } from "../utils/helpers";

function FormCampaign({
  loading,
  initValues,
  deactivateCampaign,
  onSubmit,
  deleteCampaign,
  campaign,
  submitText = "Créer la campagne",
}) {
  const [fields, setFields] = useState(initValues);
  const updateField = (value, field) => {
    if (field === "media") {
      setFields({ ...fields, media: value, media_thumb: null });
    } else {
      setFields({ ...fields, [field]: value });
    }
  };
  useEffect(() => {
    if (campaign) {
      setFields(campaign);
    } else {
      setFields(
        initValues || {
          ppc: MIN_PPC + 25,
          ppp: 0,
          name: "",
          description: "",
          link: "",
          media: undefined,
        }
      );
    }
  }, [campaign, campaign?.uuid]);

  return (
    <Formik
      isSubmitting={loading}
      initialValues={fields}
      onSubmit={(values, actions) => {
        onSubmit(fields);
      }}
    >
      {(props) => (
        <Form>
          {/* {fields.active !== undefined && (
            <FormControl my={8}>
              <FormLabel mb={0}>
                Statut de la campagne* <ChakraBadge colorScheme={fields.active ? "green" : "red"}>{fields.active ? "" : "IN"}ACTIVE</ChakraBadge>
              </FormLabel>

              <Switch
                colorScheme="whatsapp"
                size="lg"
                isChecked={fields.active}
                onChange={(e) => {
                  updateField(e.target.checked, "active");
                }}
              />
            </FormControl>
          )} */}
          <FormControl isInvalid={fields.name === ""} my={8}>
            <FormLabel mb={0}>Nom de la campagne*</FormLabel>
            <Input
              type="text"
              px={6}
              py={7}
              placeholder="Nom de la campagne"
              defaultValue={fields.name}
              onChange={(e) => updateField(e.target.value, "name")}
            />
          </FormControl>

          <FormControl my={8}>
            <FormLabel mb={0}>Description</FormLabel>
            <Text as="small">
              Votre description permettra aux personnes qui partagent votre
              contenu de mieux comprendre la campagne, l'entreprise, vos
              attentes, etc.
            </Text>
            <Textarea
              type="text"
              placeholder="Nous faisons des courses pour nos clients et leur permettons de tout recevoir à domicile. Nous ciblons principalement les mamans qui peuvent alors commander sur notre site lamater.net. "
              defaultValue={fields.description}
              onChange={(e) => updateField(e.target.value, "description")}
            />
          </FormControl>

          <FormControl my={8}>
            <FormLabel mb={0}>Media à partager</FormLabel>
            <Text as="small">
              Image ou video(de 30 secondes) qui sera partagée sur les réseaux.
              (
              {campaign?.is_active
                ? "Désactivez la campagne pour pouvoir modifier l'affiche"
                : ""}
              )
            </Text>
            <MediaUploader
              isEditable={campaign && campaign?.is_active ? false : true}
              defaultMedia={fields.media}
              thumbnail={fields.media_thumb}
              onChange={(media) => {
                updateField(media, "media");
              }}
            />
          </FormControl>
          <FormControl
            isInvalid={fields.link === "" || !validLink(fields.link)}
            my={8}
          >
            <FormLabel mb={0}>Lien à partager*</FormLabel>
            <Text as="small">
              Ici, vous devez fournir le lien où vous souhaitez que les clients
              soient redirigés après le clic. Il peut s'agir d'un lien vers
              votre site web, votre whatsapp ou encore une page de vos réseaux
              sociaux.
            </Text>
            <LinkToShareInput
              onChange={(value) => updateField(value, "link")}
              defaultValue={fields.link}
            />
          </FormControl>
          <FormControl
            isInvalid={fields.ppc === "" || fields.ppc < MIN_PPC}
            my={8}
          >
            <FormLabel mb={0}>Coût par clic*</FormLabel>
            <Text as="small">
              Le montant à payer à chaque clic sur votre lien. Plus votre prix
              est élevé, plus de personnes voudront le partager et vous
              toucherez plus de personnes.
            </Text>
            <InputGroup>
              <Input
                type="number"
                step={5}
                min={MIN_PPC}
                px={6}
                py={7}
                placeholder={`Min. ${MIN_PPC} FCFA`}
                defaultValue={fields.ppc}
                onChange={(e) => updateField(e.target.value, "ppc")}
              />
              <InputRightElement>
                <Text fontWeight={700} color={darkGreen} pt={5} pr={6}>
                  XAF
                </Text>
              </InputRightElement>
            </InputGroup>
          </FormControl>
          {/* <FormControl isInvalid={fields.ppp === "" || fields.ppp < MIN_PPP} my={8}>
            <FormLabel mb={0}>Coût par partage*</FormLabel>
            <Text as="small">Le montant à payer à chaque partage sur les réseaux. Plus votre prix est élevé, plus de personnes voudront le partager et vous toucherez plus de personnes.</Text>
            <InputGroup>
              <Input type="number" step={5} min={MIN_PPP} px={6} py={7} placeholder={`Min. ${MIN_PPP} FCFA`} defaultValue={fields.ppp} onChange={(e) => updateField(e.target.value, "ppp")} />
              <InputRightElement>
                <Text fontWeight={700} color={darkGreen} pt={5} pr={6}>
                  XAF
                </Text>
              </InputRightElement>
            </InputGroup>
          </FormControl> */}

          <FormControl my={"4"}>
            <Text as="small" fontSize={"xs"}>
              (*) : Champs obligatoires
            </Text>
          </FormControl>
          {!campaign && (
            <FormControl mt={"4"}>
              <Text as="small">
                En créant cette campagne, vous acceptez nos{" "}
                <Link
                  isExternal
                  href="/legal"
                  target="_blank"
                  color={primaryColor}
                >
                  termes et conditions
                </Link>
                .
              </Text>
            </FormControl>
          )}
          <FormControl>
            {campaign ? (
              !campaign?.is_active && (
                <Button
                  mb={5}
                  isLoading={loading}
                  type="submit"
                  colorScheme={"whatsapp"}
                  size="lg"
                  w="100%"
                >
                  {submitText}
                </Button>
              )
            ) : (
              <Button
                mb={5}
                isLoading={loading}
                type="submit"
                colorScheme={"whatsapp"}
                size="lg"
                w="100%"
              >
                {submitText}
              </Button>
            )}

            {campaign && campaign?.is_active === 1 && (
              <Button
                mb={5}
                isLoading={loading}
                type="button"
                onClick={deactivateCampaign}
                colorScheme={"yellow"}
                size="lg"
                w="100%"
              >
                Désactiver la campagne
              </Button>
            )}
            {campaign && (
              <Button
                mb={5}
                isLoading={loading}
                type="button"
                onClick={deleteCampaign}
                colorScheme={"red"}
                size="lg"
                w="100%"
                disabled={
                  campaign?.is_active &&
                  campaign?.stats?.total_participants?.all > 0
                }
              >
                Supprimer la campagne
              </Button>
            )}
            <Link href="/">
              <Button type="button" size="lg" w="100%">
                Revenir à l'accueil
              </Button>
            </Link>
          </FormControl>
        </Form>
      )}
    </Formik>
  );
}

export default FormCampaign;
