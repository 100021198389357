import { AddIcon } from "@chakra-ui/icons";
import { Box, Center, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { useGlobalState } from "../../store/GlobalStore";
import { darkGreen } from "../../utils/constants";

function NewCampaign() {
  const { color_theme } = useGlobalState();
  return (
    <Link to={"/campaigns/create"}>
      <Box bgColor={color_theme === "light" ? "#F8F8F8" : "#333"} boxShadow={"base"} my="5" borderRadius={"10px"} border={"3px dashed " + darkGreen + "66"} overflow="hidden" height="100px" cursor={"pointer"}>
        <Center w="100%" py={0} h="100%">
          <VStack>
            <AddIcon color={darkGreen}/>
            <Text color={darkGreen} fontSize="20px" textTransform={"uppercase"} fontWeight="500">
              Nouvelle campagne
            </Text>
          </VStack>
        </Center>
      </Box>
    </Link>
  );
}

export default NewCampaign;
