/* eslint-disable react-hooks/exhaustive-deps */
import { Container, useToast } from "@chakra-ui/react";
import React, { useEffect } from "react";
import Layout from "../components/global/Layout";
import Title from "../components/global/Title";
import Breadcrumb from "../partials/Breadcrumb";
import FormCampaign from "../partials/FormCampaign";
import { createCampaign, useCampaignDispatch, useCampaignState } from "../store/CampaignStore";
import { MIN_PPC } from "../utils/constants";
import { isAGoodUrl } from "../utils/helpers";

function CampaignCreate() {
  const toast = useToast();
  const { error, loading } = useCampaignState();
  const dispatch = useCampaignDispatch();

  const breadcrumbItems = [
    {
      path: "/",
      name: "Accueil",
    },
    {
      path: "/",
      name: "Campaigns",
    },
    {
      path: "#",
      name: "Create",
    },
  ];


  useEffect(() => {
    if (error) {
      toast({
        title: "Erreur - Création d'une campagne",
        description: error.error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [error]);

  const submitForm = (fields) => {
    if (fields.name !== "" && fields.link !== ""  && isAGoodUrl(fields.link) && fields.ppc >= MIN_PPC) {
      createCampaign(dispatch, fields);
    } else {
      toast({
        title: "Erreur - Création d'une campagne",
        description: "Veuillez remplir tous les champs et respecter les prix minimums.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
    console.log(fields);
  };

  return (
    <Layout>
      <Breadcrumb items={breadcrumbItems} />
      <Container>
        <Title>Nouvelle campagne</Title>
        <FormCampaign
          onSubmit={submitForm}
          loading={loading}
          initValues={{
            ppc: MIN_PPC + 25,
            ppp: 0,
            name: "",
            description: "",
            link: "",
            media: undefined,
          }}
        />
      </Container>
    </Layout>
  );
}

export default CampaignCreate;
