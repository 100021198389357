import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Breadcrumb as ChakraBreadcrumb,
  BreadcrumbItem,
  Container,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

function Breadcrumb({ items }) {
  return (
    <Container mb="5">
      <ChakraBreadcrumb separator={<ChevronRightIcon/>}>

        {items.map((item, id) => {
          return (
            <BreadcrumbItem key={"breadcrumb_" + id}>
              <Link to={item.path}>
                <Text as="small">{item.name}</Text>
              </Link>
            </BreadcrumbItem>
          );
        })}
      </ChakraBreadcrumb>
    </Container>
  );
}

export default Breadcrumb;
