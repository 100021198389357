import { Box, Button, Center, Container, FormControl, Image, Input, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { Navigate } from "react-router";
import { Link } from "react-router-dom";
import PhoneInput from "../components/global/PhoneInput";
import Footer from "../partials/Footer";
import { registerUser, useGlobalDispatch, useGlobalState } from "../store/GlobalStore";
import { primaryColor } from "../utils/constants";

function Register() {
  const { color_theme, loading, error, user } = useGlobalState();
  const dispatch = useGlobalDispatch();
  const [fields, setFields] = useState({
    tel: "",
    password: "",
    confirm_password: "",
    name: "",
  });
  const changeField = (value, field) => {
    setFields({ ...fields, [field]: value });
  };
  const register = () => {
    registerUser(dispatch, fields);
  };

  if (user && !error) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Container>
        <Box w="100%" py={18}>
          <Center mt={5}>
            <Image src={color_theme === "light" ? "/logo.png" : "/logo_white.png"} height={"3rem"} alt="Logo de Publish" />
          </Center>
          <Box textAlign={"center"} mt={8} px={5}>
            <span>
              Gagnez de l'argent en partageant des contenus sur vos réseaux : <br />
              <strong>Whatsapp, Dikalo, Twitter, Facebook, etc</strong>
            </span>
          </Box>
        </Box>
        <Box textAlign={"center"} px={5}>
          <Text fontSize="4xl" fontWeight={"800"} my={10}>
            Inscription
          </Text>
          <FormControl my="4">
            <Input type="text" px={6} py={7} placeholder="Prénom et nom" onChange={(e) => changeField(e.target.value, "name")} />
          </FormControl>
          <FormControl my="4">
            <PhoneInput placeholder="Votre numéro de téléphone" value={fields.tel} onChange={(v) => changeField(v, "tel")} />
          </FormControl>
          <FormControl my="4">
            <Input type="password" px={6} py={7} placeholder="Mot de passe" onChange={(e) => changeField(e.target.value, "password")} />
          </FormControl>
          <FormControl my="4">
            <Input type="password" px={6} py={7} placeholder="Confirmation de mot de passe" onChange={(e) => changeField(e.target.value, "confirm_password")} />
          </FormControl>

          <Text as="small" textAlign={"right"}> 
            En vous inscrivant, vous acceptez nos{" "}
            <Link to="/legal">
              <Text color={primaryColor} as="span">
                conditions d'utilisation
              </Text>
            </Link>
            .
          </Text>
            <br />
          <Button bgColor={primaryColor} onClick={register} fontWeight={500} width="100%" color="white" py={7} isLoading={loading}>
            S'inscrire
          </Button>
        </Box>
        <Box my={8} mt={4} px={5} textAlign={"right"} fontSize={20}>
          <Text as="small">
            Vous avez déjà un compte ?{" "}
            <Link to={"/login"}>
              <Text color={primaryColor} as="span">
                connectez-vous
              </Text>
            </Link>
            .
          </Text>
        </Box>
      </Container>
      <Footer />
    </>
  );
}

export default Register;
