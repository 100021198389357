/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Center, Container, Flex, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import TransactionLine from "../components/campaigns/TransactionLine";
import Badge from "../components/global/Badge";
import ErrorBox from "../components/global/ErrorBox";
import Title from "../components/global/Title";
import RechargeAccount from "../components/transactions/RechargeAccount";
import RemoveMoneyButton from "../components/transactions/RemoveMoneyButton";
import LoadingPage from "../pages/LoadingPage";
import { getAllTransactions, useGlobalDispatch, useGlobalState } from "../store/GlobalStore";
import { transaction_status } from "../utils/constants";

function TransactionsBox() {
  const { loading, user, error, transactions } = useGlobalState();
  const dispatch = useGlobalDispatch();
  const [seeAll, setSeeAll] = useState(false)
  const [activeStatus, setActiveStatus] = useState("all");
  const filteredTransactions = (transactions || []).filter((t) => (activeStatus === "all" ? true : t.status === activeStatus));
  const filter = (_filter) => {
    setActiveStatus(_filter);
  };
  useEffect(() => {
    getAllTransactions(dispatch);
  }, []);

  if (loading) return <LoadingPage />;
  if (error) return <ErrorBox />;
  return (
    <Container my={10}>
      <Title>Transactions</Title>
      <Box textAlign={"right"}>
        {/* <RemoveMoneyButton user={user} /> */}
        {/* <RechargeAccount user={user} /> */}
      </Box>
      {transactions && transactions.length === 0 && (
        <Box my={20} textAlign={"center"}>
          <Text>Aucune somme n'a encore été ajoutée.</Text>
        </Box>
      )}
      {transactions && transactions.length > 0 && (
        <>
          <Flex wrap={"wrap"} my="4">
            <Badge
              onClick={() => {
                filter("all");
              }}
              active={activeStatus === "all"}
            >
              Toutes les transactions
            </Badge>
            <Badge
              onClick={() => {
                filter("pending");
              }}
              active={activeStatus === "pending"}
            >
              {transaction_status.pending}
            </Badge>
            <Badge
              onClick={() => {
                filter("done");
              }}
              active={activeStatus === "done"}
            >
              {transaction_status.done}
            </Badge>{" "}
            <Badge
              onClick={() => {
                filter("failed");
              }}
              active={activeStatus === "failed"}
            >
              {transaction_status.failed}
            </Badge>
          </Flex>
          <Box my="10">
            {filteredTransactions.slice(0, !seeAll ? 5 : transactions.length).map((transaction) => {
              return <TransactionLine transaction={transaction} key={Math.random()} />;
            })}
            <Center>
              <Button colorScheme={"whatsapp"} onClick={() => setSeeAll(!seeAll)}>
                {seeAll ? "Moins voir" : "Tout voir"}
              </Button>
            </Center>
          </Box>
        </>
      )}
    </Container>
  );
}

export default TransactionsBox;
