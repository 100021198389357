import React from "react";

function Pagination() {
  // const pagination = {
  //   total: 50,
  //   per_page: 15,
  //   current_page: 1,
  //   last_page: 4,
  //   first_page: 1,
  //   next_page: 2,
  //   prev_page: null,
  //   prev_page_url: null,
  //   path: "http://publish.cm",
  //   from: 1,
  //   to: 15,
  // };
  return <></>;
}

export default Pagination;
