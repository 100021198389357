import { Center, Container, Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import Badge from "../components/global/Badge";
import TextNumber from "../components/global/TextNumber";
import Title from "../components/global/Title";

function Statistics({ stats }) {
  const [period, setPeriod] = useState('today')
  return (
    <Container my="5">
      <Title mb={5  }>Statistiques</Title>
      <Flex wrap={"wrap"}>
        <Badge onClick={() => {
          setPeriod('all')
        }} active={period === 'all'}>Toute la période</Badge>
        <Badge onClick={() => {
          setPeriod('week')
        }} active={period === 'week'}>Cette semaine</Badge>
        <Badge onClick={() => {
          setPeriod('today')
        }} active={period === 'today'}>Aujourd'hui</Badge>
      </Flex>
      {stats.map((stat) => {
        return (
          <Center key={Math.random()} mt={5}>
            <TextNumber number={stat.value[period] || 0} text={stat.label} />
          </Center>
        );
      })}
    </Container>
  );
}

export default Statistics;
