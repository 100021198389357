module.exports = {
  MIN_PPC: 15,
  MIN_PPP: 5,
  MIN_AMOUNT_TO_RECHARGE: 1000,
  STRIPE_FEES: 0.025,
  primaryColor: "#3FC250",
  darkGreen: "#0D3713",
  minWithdrawableMoney: 5000,
  transaction_status: {
    pending: "En traitement",
    failed: "Echouée",
    done: "Effectuée",
    all: "Toutes",
  },
};
