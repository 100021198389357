import { Center, Spinner } from "@chakra-ui/react";
import React from "react";
import { primaryColor } from "../utils/constants";

function LoadingPage({ h = "100vh", w = "100vw", ...props }) {
  return (
    <Center
      h={h}
      w={w}
      position={"fixed"}
      bgColor="white"
      opacity={"90%"}
      zIndex={"500"}
      top={0}
      left={0}
      {...props}
    >
      <Spinner color={primaryColor} size={"lg"} />
    </Center>
  );
}

export default LoadingPage;
