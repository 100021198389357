import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import Moment from "react-moment";
import { useGlobalState } from "../../store/GlobalStore";
import { transaction_status } from "../../utils/constants";
import { transactionStatusColor } from "../../utils/helpers";

function TransactionLine({ transaction }) {
  const {user} = useGlobalState();
  return (
    <Box bgColor={"#F9FFF9"} my={2} p={4}>
      <Flex alignItems={"center"} w="100%" justifyContent={"space-between"}>
        <Text fontSize={"1.25rem"} fontWeight={800}>
          {transaction.to !== user.id ? "-" : ""} {transaction.amount} XAF
        </Text>
        <Text fontWeight={700} textTransform={"uppercase"} color={transactionStatusColor(transaction.status)}>
          {transaction_status[transaction.status]}
        </Text>
      </Flex>
      <Flex alignItems={"center"} w="100%" justifyContent={"space-between"}>
        <Text fontSize={".7rem"} fontWeight={500}>
          {transaction.mode}
        </Text>
        <Text fontSize={".7rem"} fontWeight={400}>
          Initiée <Moment fromNow>{transaction.updated_at}</Moment>
        </Text>
      </Flex>
    </Box>
  );
}

export default TransactionLine;
