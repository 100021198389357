import { Button, Center, Text } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import Layout from "../components/global/Layout";
import { primaryColor } from "../utils/constants";

function NoMatch() {
  return (
    <Layout>
      <Center textAlign={"center"}>
        <Text fontWeight={"800"} fontSize={"5rem"} color={primaryColor} my={10}>404 <br/> PAGE NOT FOUND</Text>
      </Center>
      <Center>
        <br/>
        <br/>
        <Link my={10} to="/">
          <Button>Back to home</Button>
        </Link>
      </Center>
    </Layout>
  );
}

export default NoMatch;
